import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { format, parse, default_date_format } from "../../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../../js/lib/Translator";
import DatePicker from "../../../../../../../js/react/components/general/DatePicker";
import DeleteDialog from "../../../../../../../js/react/components/general/DeleteDialog";
import Dialog from "../../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../../../js/react/components/general/form/checkmark/Checkmark";
import InitialsTextInput from "../../../../../../../js/react/components/general/form/InitialsTextInput";
import LabeledTextInput from "../../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";
import CompanyDossierContext from "../CompanyDossierContext";
import { error } from "../../../../../../../js/react/components/general/notifications";
import { route } from "../../../../../../../js/helpers";
import DossierFileInput from "../fileInput/DossierFileInput";
import ConflictingDossierDirector from "./ConflictingDossierDirector";
import DossierPartOrganisationIsCentral from "./DossierPartOrganisationIsCentral";

export default function DossierDirectorPart({ completionData }) {
	const { activeDossierDirectors, salutationTypes, handleDirectorChange, isIntermediary, companyId, readOnly } =
		useContext(CompanyDossierContext);
	const [activeDirector, setActiveDirector] = useState(null);
	const archivedDossierDirectors = activeDossierDirectors.filter(director => director.archived && !director.deleted);
	const [shouldDeleteDirector, setShouldDeleteDirector] = useState(null);
	const [shouldDeleteContactPerson, setShouldDeleteContactPerson] = useState(null);
	const [showArchivedFiles, setShowArchivedFiles] = useState(false);

	const handleDialogCancel = () => {
		setActiveDirector(null);
	};

	const handleDialogSave = () => {
		if (activeDirector !== null) {
			if (
				activeDirector.values.first_name !== "" &&
				activeDirector.values.last_name !== "" &&
				(activeDirector.values.archived || activeDirector.values.date_of_birth !== null) &&
				activeDirector.values.initials
			) {
				const newActiveDossierDirectors = Array.isArray(activeDossierDirectors) ? [...activeDossierDirectors] : [];
				if (activeDirector.creating) {
					newActiveDossierDirectors.push(activeDirector.values);
				} else {
					newActiveDossierDirectors[activeDirector.index] = activeDirector.values;
				}
				handleDirectorChange(newActiveDossierDirectors);
				setActiveDirector(null);
			} else {
				if (activeDirector.values.salutation === "") {
					error(uctrans("dossiers.directors.fields.no_salutation_filled"));
				}
				if (activeDirector.values.initials === "") {
					error(uctrans("dossiers.directors.fields.no_initials_filled"));
				}
				if (activeDirector.values.last_name === "") {
					error(uctrans("dossiers.directors.fields.no_last_name_filled"));
				}
				if (activeDirector.values.first_name === "") {
					error(uctrans("dossiers.directors.fields.no_first_name_filled"));
				}
				if (activeDirector.values.date_of_birth === null) {
					error(uctrans("dossiers.directors.fields.no_date_of_birth_filled"));
				}
			}
		}
	};

	const openEditDialog = (index, values) => {
		setActiveDirector({
			creating: false,
			index,
			label: uctrans("general.edit_:item", {}, { item: "dossiers.directors.singular" }),
			values: cloneDeep(values),
		});
	};

	const openCreateDialog = () => {
		setActiveDirector({
			creating: true,
			index: -1,
			label: uctrans("general.add_:item", {}, { item: "dossiers.directors.singular" }),
			values: {
				salutation: "",
				initials: "",
				first_name: "",
				insertion: "",
				last_name: "",
				date_of_birth: null,
				create_contactperson: false,
				deleted: false,
				files: {
					files: [],
					archivedFiles: [],
				},
			},
		});
	};

	const changeActiveDossierValue = (key, value) => {
		if (activeDirector !== null) {
			const newActiveDirector = { ...activeDirector };
			newActiveDirector.values[key] = value;
			setActiveDirector(newActiveDirector);
		}
	};

	const askDeleteDossierDirector = director => {
		const index = activeDossierDirectors.findIndex(item => Number(item.id) === Number(director.id));
		if (activeDossierDirectors[index].contactperson_id) {
			setShouldDeleteContactPerson(index);
		} else {
			deleteDossierDirector(index);
		}
	};

	const deleteDossierDirector = (index, deleteContactPerson = false) => {
		const newDossierDirectors = [...activeDossierDirectors];
		newDossierDirectors[index] = {
			...newDossierDirectors[index],
			deleted: true,
			deleteContactPerson,
		};
		handleDirectorChange(newDossierDirectors);
	};

	const archiveDirector = index => {
		const newDossierDirectors = [...activeDossierDirectors];
		newDossierDirectors[index] = {
			...newDossierDirectors[index],
			archived: true,
		};
		handleDirectorChange(newDossierDirectors);
	};

	const unArchiveDirector = director => {
		const index = activeDossierDirectors.findIndex(item => Number(item.id) === Number(director.id));

		const newDossierDirectors = [...activeDossierDirectors];
		newDossierDirectors[index] = {
			...newDossierDirectors[index],
			archived: false,
		};
		handleDirectorChange(newDossierDirectors);
	};

	return (
		<>
			<div className="dossier-row">
				<DossierPartOrganisationIsCentral completionData={completionData} />
			</div>
			<div className="dossier-row">
				<label>{uctrans("dossiers.directors.singular")}</label>
				<div className="flex-1 pr-2">
					{activeDossierDirectors.map(
						(director, index) =>
							!director.deleted &&
							!director.archived && (
								<div key={index} className="border border-grey mb-2 mr-2 flex-1 p-3 w-full">
									<div className="flex">
										<div className="flex-1">
											<ConflictingDossierDirector dossierDirector={director} />
											<span>{`${director.first_name} ${director.insertion} ${director.last_name}`}</span>
										</div>
										<div className="flex blocks-center">
											<span
												className="cursor-pointer"
												onClick={() => {
													openEditDialog(index, director);
												}}>
												{readOnly ? (
													<EvaIcon
														className="flex blocks-center ml-4"
														type="eye-outline"
														width="20"
														height="20"
														fill="#009286"
													/>
												) : (
													<EvaIcon
														className="flex blocks-center ml-4"
														type="edit-outline"
														width="20"
														height="20"
														fill="#009286"
													/>
												)}
											</span>
											{!readOnly ? (
												<>
													<span
														className="cursor-pointer"
														onClick={() => {
															setShouldDeleteDirector(director);
														}}>
														<EvaIcon
															className="flex blocks-center ml-4"
															type="trash-2-outline"
															width="20"
															height="20"
															fill="#de751f"
														/>
													</span>
													{!!director.id && (
														<span
															className="cursor-pointer"
															onClick={() => {
																archiveDirector(index);
															}}>
															<EvaIcon
																className="flex blocks-center ml-4"
																type="arrow-downward-outline"
																width="20"
																height="20"
																fill="#009286"
															/>
														</span>
													)}
												</>
											) : null}
										</div>
									</div>
									<div className="mt-2">
										<DossierFileInput
											disabled
											existingFileDownloadRoute="crm.intermediaries.dossier.download-director-file"
											initialFiles={director.files.files}
											initialArchivedFiles={director.files.archivedFiles}
										/>
									</div>
								</div>
							),
					)}
				</div>
				{!readOnly ? (
					<div className={activeDossierDirectors.length === 0 ? "" : "h-12 w-12 pb-2"}>
						<button
							type="button"
							className="h-full w-full button button-upload inline-block relative"
							onClick={openCreateDialog}>
							<EvaIcon
								type="person-add-outline"
								className="absolute left-0 ml-3 top-0 mt-2"
								fill="#ffffff"
								height="20"
								width="25"
							/>
							{activeDossierDirectors.length === 0 && (
								<>{uctrans("general.add_:item", {}, { item: "dossiers.directors.singular" })}</>
							)}
						</button>
					</div>
				) : null}
			</div>

			<div className="dossier-row">
				<label />
				<div className="flex-1 pr-2">
					{archivedDossierDirectors.length > 0 &&
						(showArchivedFiles ? (
							<>
								<a className="link flex-1 inline-block mb-4" onClick={() => setShowArchivedFiles(false)}>
									<EvaIcon type="eye-off-outline" fill="#239386" height="17" width="30" />
									<small>{uctrans("dossiers.directors.fields.hide_archived_directors")}</small>
								</a>
								<br />
								{map(
									archivedDossierDirectors,
									(director, index) =>
										!director.deleted && (
											<div key={index} className="border border-grey mb-2 mr-2 flex-1 p-3 w-full">
												<div className="flex">
													<div className="flex-1">
														<ConflictingDossierDirector dossierDirector={director} />
														<span>{`${director.first_name} ${director.insertion} ${director.last_name}`}</span>
													</div>
													<div className="flex blocks-center">
														<span
															className="cursor-pointer"
															onClick={() => {
																openEditDialog(index, director);
															}}>
															{readOnly ? (
																<EvaIcon
																	className="flex blocks-center ml-4"
																	type="eye-outline"
																	width="20"
																	height="20"
																	fill="#009286"
																/>
															) : (
																<EvaIcon
																	className="flex blocks-center ml-4"
																	type="edit-outline"
																	width="20"
																	height="20"
																	fill="#009286"
																/>
															)}
														</span>
														{!readOnly ? (
															<>
																<span
																	className="cursor-pointer"
																	onClick={() => {
																		setShouldDeleteDirector(director);
																	}}>
																	<EvaIcon
																		className="flex blocks-center ml-4"
																		type="trash-2-outline"
																		width="20"
																		height="20"
																		fill="#de751f"
																	/>
																</span>
																<span
																	className="cursor-pointer"
																	onClick={() => {
																		unArchiveDirector(director);
																	}}>
																	<EvaIcon
																		className="flex blocks-center ml-4"
																		type="arrow-upward-outline"
																		width="20"
																		height="20"
																		fill="#009286"
																	/>
																</span>
															</>
														) : null}
													</div>
												</div>
												<div className="mt-2">
													<DossierFileInput
														disabled
														existingFileDownloadRoute="crm.intermediaries.dossier.download-director-file"
														initialFiles={director.files.files}
														initialArchivedFiles={director.files.archivedFiles}
													/>
												</div>
											</div>
										),
								)}
							</>
						) : (
							<>
								<a className="link flex-1" onClick={() => setShowArchivedFiles(true)}>
									<EvaIcon type="eye-outline" fill="#239386" height="17" width="30" />
									<small>{uctrans("dossiers.directors.fields.show_archived_directors")}</small>
								</a>
								<br />
							</>
						))}
				</div>
			</div>

			<Dialog
				isOpen={activeDirector !== null}
				onClose={handleDialogCancel}
				shouldCloseOnOverlayClick={false}
				width={500}
				title={activeDirector && activeDirector.label}>
				{activeDirector !== null && (
					<>
						<div className="form-full">
							<label htmlFor="salutation">{uctrans("dossiers.directors.fields.salutation")}</label>
							<RadioGroup
								name="salutation"
								disabled={readOnly}
								value={Number(activeDirector.values.salutation)}
								onChange={value => changeActiveDossierValue("salutation", value)}>
								<Radio label={salutationTypes.MR.label} value={salutationTypes.MR.key} />
								<Radio label={salutationTypes.MRS.label} value={salutationTypes.MRS.key} />
							</RadioGroup>
						</div>

						<div className="form-full">
							<InitialsTextInput
								disabled={readOnly}
								label="dossiers.directors.fields.initials"
								value={activeDirector.values.initials}
								name="initials"
								required
								onChange={e => changeActiveDossierValue("initials", e.target.value)}
							/>
						</div>

						<div className="form-full">
							<LabeledTextInput
								required
								disabled={readOnly}
								label="dossiers.directors.fields.first_name"
								value={activeDirector.values.first_name}
								name="first_name"
								onChange={e => changeActiveDossierValue("first_name", e.target.value)}
							/>
						</div>

						<div className="form-full">
							<LabeledTextInput
								disabled={readOnly}
								label="dossiers.directors.fields.insertion"
								value={activeDirector.values.insertion}
								name="insertion"
								onChange={e => changeActiveDossierValue("insertion", e.target.value)}
							/>
						</div>

						<div className="form-full">
							<LabeledTextInput
								required
								disabled={readOnly}
								label="dossiers.directors.fields.last_name"
								value={activeDirector.values.last_name}
								name="last_name"
								onChange={e => changeActiveDossierValue("last_name", e.target.value)}
							/>
						</div>

						<div className="form-full">
							<label htmlFor="date_of_birth">
								{uctrans("dossiers.directors.fields.date_of_birth")}
								{!activeDirector.values.archived && <span className="text-orange"> *</span>}
							</label>
							<DatePicker
								disabled={readOnly}
								label="dossiers.directors.fields.date_of_birth"
								initialValue={
									activeDirector.values.date_of_birth !== null
										? parse(activeDirector.values.date_of_birth, default_date_format)
										: null
								}
								name="date_of_birth"
								onChange={date =>
									changeActiveDossierValue("date_of_birth", date ? format(date, default_date_format) : null)
								}
							/>
						</div>

						{activeDirector.values.contactperson_id ? (
							<>
								<label>{uctrans("dossiers.directors.fields.contact_person_was_made")}</label>
								<div className="form-full">
									<a
										href={
											isIntermediary
												? route("crm.intermediaries.contactpersons.edit", {
														intermediary: companyId,
														contactperson: activeDirector.values.contactperson_id,
												  })
												: route("crm.organisations.contactpersons.edit", {
														organisation: companyId,
														contactperson: activeDirector.values.contactperson_id,
												  })
										}
										className="link">
										{uctrans("dossiers.directors.fields.go_to_contact_person")}
									</a>
								</div>
							</>
						) : (
							<div className="form-full">
								<Checkmark
									disabled={readOnly}
									onChange={e => changeActiveDossierValue("create_contactperson", e.target.checked)}
									name="create_contactperson"
									checked={activeDirector.values.create_contactperson}
									label={uctrans("dossiers.directors.fields.create_contact_person_when_saving")}
								/>
							</div>
						)}
						<div className="form-full">
							<label htmlFor="type">{uctrans("dossiers.fields.files")}</label>
							<DossierFileInput
								maxFiles={10}
								disabled={readOnly}
								existingFileDownloadRoute="crm.intermediaries.dossier.download-director-file"
								initialFiles={activeDirector.values.files.files}
								initialArchivedFiles={activeDirector.values.files.archivedFiles}
								onChange={files => changeActiveDossierValue("files", files)}
							/>
						</div>

						{!readOnly ? (
							<div className="form-full">
								<div className="mt-6">
									<button type="button" className="button button-primary button-dialog" onClick={handleDialogSave}>
										{uctrans("general.ok")}
									</button>
									<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
								</div>
							</div>
						) : null}
					</>
				)}
			</Dialog>

			<DeleteDialog
				isOpen={shouldDeleteDirector !== null}
				deleteData={{
					errors: [],
					warnings: [],
					message: `${uctrans(
						"general.confirm_delete_:item_:label",
						{
							label: shouldDeleteDirector ? `${shouldDeleteDirector.first_name} ${shouldDeleteDirector.last_name}` : "",
						},
						{ item: "dossiers.directors.singular" },
					)} 
					
					${uctrans("dossiers.warnings.delete_director")}`,
				}}
				onConfirm={() => {
					askDeleteDossierDirector(shouldDeleteDirector);
					setShouldDeleteDirector(null);
				}}
				onCancel={() => setShouldDeleteDirector(null)}
			/>

			<Dialog
				isOpen={shouldDeleteContactPerson !== null}
				onClose={() => setShouldDeleteContactPerson(null)}
				confirmButtonLabel={uctrans("general.yes")}
				cancelButtonLabel={uctrans("general.no")}
				title={uctrans("dossiers.directors.fields.delete_contactperson_when_deleting")}>
				<a
					className="button background-color-success button-primary"
					onClick={() => {
						deleteDossierDirector(shouldDeleteContactPerson, true);
						setShouldDeleteContactPerson(null);
					}}>
					{uctrans("general.yes")}
				</a>

				<a
					className="button link text-grey-darker ml-5"
					onClick={() => {
						deleteDossierDirector(shouldDeleteContactPerson);
						setShouldDeleteContactPerson(null);
					}}>
					{uctrans("general.no")}
				</a>
			</Dialog>
		</>
	);
}

DossierDirectorPart.propTypes = {
	completionData: PropTypes.object,
};
