import PropTypes from "prop-types";
import axios from "axios";
import lowerFirst from "lodash/lowerFirst";
import { useState, useEffect, useCallback } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import { route } from "../../../../../js/helpers";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Dialog from "../../../../../js/react/components/general/Dialog";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import CopyCompanyDataDialog from "../intermediaries/CopyCompanyDataDialog";
import WarningGroups from "./warnings/WarningGroups";

export default function SideBarStatusButton(props) {
	const isProspectRejected = Number(props.company_status) === Number(props.company_statuses.PROSPECT_REJECTED.key);
	const isActive = Number(props.company_status) === Number(props.company_statuses.ACTIVE.key);
	const isProspect = Number(props.company_status) === Number(props.company_statuses.PROSPECT.key);
	const isNonActive = Number(props.company_status) === Number(props.company_statuses.NOT_ACTIVE.key);
	const canChangeStatus = !isProspect;
	const [prospectConfirm, setProspectConfirm] = useState(false);
	const [buttonContent, setButtonContent] = useState(null);
	const [buttonLink, setButtonLink] = useState(null);
	const [showBackToProspect, setShowBackToProspect] = useState(false);
	const [showCopyCompanyDataDialog, setShowCopyCompanyDataDialog] = useState(false);
	const [dialogTitle, setDialogTitle] = useState(null);
	const [dialogContent, setDialogContent] = useState(null);
	const [currentLink, setCurrentLink] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [statusChange, setStatusChange] = useState("");
	const [deactivationWarnings, setDeactivationWarnings] = useState(null);
	const [formData, setFormData] = useState({});
	const [canSubmit, setCanSubmit] = useState(false);

	const getRoute = newStatus =>
		props.intermediary
			? route("crm.intermediaries.update-status", {
					intermediary: props.company_id,
					new_status: newStatus,
					company_id: props.company_id,
			  })
			: props.organisation
			  ? route("crm.organisations.update-status", {
						organisation: props.company_id,
						new_status: newStatus,
						company_id: props.company_id,
			    })
			  : null;

	const handleCopyCompanyDataSuccess = () => {
		window.onbeforeunload = null;
		window.location.reload();
	};

	const getDialogContent = transLabel =>
		uctrans(
			transLabel,
			{},
			{
				company: props.intermediary ? "intermediaries.singular" : props.organisation ? "organisations.singular" : "",
				companies: props.intermediary ? "intermediaries.plural" : props.organisation ? "organisations.plural" : "",
			},
		);

	const fetchDeactivationWarnings = useCallback(async () => {
		try {
			const { data } = await axios.get(route("crm.companies.deactivation-warnings", props.company_id));
			setDeactivationWarnings(data.groups);
		} catch (error) {
			console.error(error);
		}
	}, [props.company_id]);

	useEffect(() => {
		if (isOpen && isActive) {
			// fetch deactivation warnings
			fetchDeactivationWarnings();
		}
	}, [fetchDeactivationWarnings, isActive, isOpen]);

	useEffect(() => {
		setStatusChange("");
	}, [isOpen, prospectConfirm]);

	useEffect(() => {
		if (canChangeStatus) {
			if (isActive) {
				const notActiveLabel = uctrans("companies.change_status_to_:status", {
					status: lowerFirst(props.company_statuses.NOT_ACTIVE.label),
				});
				setButtonContent(notActiveLabel);
				setButtonLink(getRoute(props.company_statuses.NOT_ACTIVE.key));

				setDialogTitle(notActiveLabel);

				setShowBackToProspect(false);
			} else if (isNonActive) {
				const activeLabel = uctrans("companies.change_status_to_:status", {
					status: lowerFirst(props.company_statuses.ACTIVE.label),
				});
				setButtonContent(activeLabel);
				setButtonLink(getRoute(props.company_statuses.ACTIVE.key));
				setDialogTitle(activeLabel);
				setDialogContent([{ text: getDialogContent("companies.confirms.activate_:company_:companies") }]);

				setShowBackToProspect(false);
			} else if (isProspectRejected) {
				setShowBackToProspect(true);
			} else if (isProspect) {
				setButtonContent(
					uctrans("companies.change_status_to_:status", {
						status: lowerFirst(props.company_statuses.PROSPECT_REJECTED.label),
					}),
				);
				setButtonLink(getRoute(props.company_statuses.PROSPECT_REJECTED.key));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openDialog = link => {
		setCurrentLink(link);
		setIsOpen(true);
	};

	const submitUrl = link => (typeof link !== "undefined" ? link : currentLink);

	const changeFormData = useCallback(
		(key, value) => {
			const newFormData = { ...formData };
			newFormData[key] = value;
			setFormData(newFormData);
		},
		[formData],
	);

	const formFields = content => (
		<div>
			{content && content.length && (
				<WarningGroups
					groups={content}
					onClose={() => setIsOpen(false)}
					flat
					onApprove={approved => {
						approved.forEach(warningKey => changeFormData(warningKey, true));
						setCanSubmit(true);
					}}
					warningsAsList={false}
				/>
			)}
			<br />
			<div className="form-full">
				<label htmlFor="status_id_comment">
					<Translate content="intermediaries.fields.status_id_comment" />
					<span className="text-orange"> *</span>
				</label>
				<textarea
					id="status_change"
					name="status_change"
					value={formData.status_change || ""}
					onChange={e => changeFormData("status_change", e.target.value)}
					placeholder={uctrans("intermediaries.status_comment_placeholder")}
					autoComplete="off"
				/>
			</div>

			<div className="form-full">
				<div className="w-full md:w-1/2 lg:w-2/3 column">
					<SubmitBar
						submitDisabled={!canSubmit}
						item="intermediaries.fields.status_change"
						addAnotherAvailable={false}
						onCancel={() => {
							setIsOpen(false);
							setProspectConfirm(false);
						}}
					/>
				</div>
			</div>
		</div>
	);

	return props.company_id ? (
		<>
			{buttonContent !== null && buttonLink !== null && (
				<div className="form-full">
					<a className="button button-primary" onClick={() => openDialog(buttonLink)}>
						{buttonContent}
					</a>

					<Dialog isOpen={isOpen} title={dialogTitle} onClose={() => setIsOpen(false)}>
						<AjaxForm
							method="PUT"
							submitUrl={submitUrl()}
							loaderText={uctrans("general.:item_is_being_saved", {}, { item: "intermediaries.fields.status_change" })}
							successText={uctrans("general.saved_:item", {}, { item: "intermediaries.fields.status_change" })}
							onSuccess={() => window.location.reload()}
							data={{ ...formData }}
							useFlashMessage>
							{formFields(isActive ? deactivationWarnings : dialogContent)}
						</AjaxForm>
					</Dialog>
				</div>
			)}
			{showBackToProspect && (
				<div className="form-full">
					<a className="button button-primary" onClick={() => setProspectConfirm(true)}>
						{uctrans("companies.change_status_to_:status", {
							status: lowerFirst(props.company_statuses.PROSPECT.label),
						})}
					</a>
					<Dialog
						isOpen={prospectConfirm}
						title={uctrans("companies.change_status_to_:status", {
							status: lowerFirst(props.company_statuses.PROSPECT.label),
						})}
						onClose={() => setProspectConfirm(false)}>
						<AjaxForm
							method="PUT"
							onSubmit={() => setProspectConfirm(false)}
							submitUrl={
								props.intermediary
									? route("crm.intermediaries.update-status", {
											intermediary: props.company_id,
											new_status: props.company_statuses.PROSPECT.key,
											status_change: statusChange,
											company_id: props.company_id,
									  })
									: props.organisation
									  ? route("crm.organisations.update-status", {
												organisation: props.company_id,
												new_status: props.company_statuses.PROSPECT.key,
												status_change: statusChange,
												company_id: props.company_id,
									    })
									  : null
							}
							loaderText={uctrans("general.:item_is_being_saved", {}, { item: "intermediaries.fields.status_change" })}
							successText={uctrans("general.saved_:item", {}, { item: "intermediaries.fields.status_change" })}
							onSuccess={() => window.location.reload()}
							useFlashMessage>
							{formFields([
								{
									key: "default",
									warnings: [{ text: getDialogContent("companies.confirms.prospect_:company_:companies") }],
								},
							])}
						</AjaxForm>
					</Dialog>
				</div>
			)}
			{!props.organisation && (
				<div className="form-full">
					<a className="button button-primary" onClick={() => setShowCopyCompanyDataDialog(true)}>
						{uctrans("companies.copy_data_from_inactive_company")}
					</a>
					<CopyCompanyDataDialog
						targetCompanyId={props.company_id}
						isOpen={showCopyCompanyDataDialog}
						onClose={() => setShowCopyCompanyDataDialog(false)}
						onSuccess={() => handleCopyCompanyDataSuccess()}
					/>
				</div>
			)}
		</>
	) : (
		<div className="form-full">
			<span>{uctrans("companies.save_company_first")}</span>
		</div>
	);
}
SideBarStatusButton.propTypes = {
	company_id: PropTypes.number,
	company_status: PropTypes.number,
	company_statuses: PropTypes.object,
	organisation: PropTypes.bool,
	intermediary: PropTypes.bool,
};

SideBarStatusButton.defaultProps = {
	organisation: false,
	intermediary: false,
};
