import { useContext, useState } from "react";
import { format } from "@/lib/Date";
import { uctrans } from "@/lib/Translator";
import FormattedNumberText from "../report/FormattedNumberText";
import TitleBlock from "./components/TitleBlock";
import LoanResultsLineChart from "./graph/LoanResultsLineChart";
import RmDashboardBlock from "./RmDashboardBlock";
import { RmDashboardContext } from "./RmDashboardContext";

export default function LoanResultsBlock() {
	const [productionMode, setProductionMode] = useState("sum");
	const { wallet } = useContext(RmDashboardContext);

	return (
		<RmDashboardBlock
			blockKey="loan-results"
			useFetching
			renderHeader={blockData => (
				<div className="flex w-full justify-between">
					<div className="w-2/3">
						<div className="flex w-full">
							<h5 className="mb-0">
								<TitleBlock
									title={uctrans("dashboard.loan_results")}
									dashboardLink={blockData && blockData.dashboardLink}
									walletType={wallet.type}
								/>
							</h5>

							{blockData && blockData.last_import && blockData.last_data && (
								<>
									{" | "}
									<span className="text-sm ml-2 mt-1 mr-2">
										{uctrans("dashboard.last_update_:date", {
											date: format(blockData.last_import, "dd-MM-y"),
										})}
									</span>
									{" | "}
									<span className="text-sm ml-2 mt-1">
										{uctrans("dashboard.last_data:date", {
											date: format(blockData.last_data, "dd-MM-y"),
										})}
									</span>
								</>
							)}
						</div>
					</div>
					{!!blockData && (
						<div className="w-1/3 text-right mr-5">
							<a
								className={`${productionMode === "sum" ? "cursor-default font-bold text-grey-darkest" : "link"}`}
								onClick={() => setProductionMode("sum")}>
								{uctrans("dashboard.sum_x_1000")}
							</a>{" "}
							|{" "}
							<a
								className={`${productionMode === "count" ? "cursor-default font-bold text-grey-darkest" : "link"}`}
								onClick={() => setProductionMode("count")}>
								{uctrans("dashboard.count")}
							</a>
						</div>
					)}
				</div>
			)}
			renderContent={blockData => (
				<div className="flex">
					<div className="w-1/2">
						<div>
							<table className="table table-rm-dashboard">
								<thead>
									<tr>
										<td className="bg-primary text-white font-bold" colSpan={5}>
											{blockData.year}
										</td>
									</tr>
									<tr>
										<td className="font-bold">Status</td>
										<td className="font-bold">Realisatie</td>
										<td className="font-bold">Doel YTD</td>
										<td className="font-bold">%</td>
										<td className="font-bold">Jaardoel</td>
									</tr>
								</thead>
								<tbody>
									{blockData.targetStatistics.map((statistic, key) =>
										statistic.statusCode === 210 || statistic.statusCode === 220 || statistic.statusCode === 320 ? (
											<tr key={key}>
												<td>{statistic.statusLabel}</td>
												<td>
													<FormattedNumberText
														prefix={productionMode === "sum" ? "€ " : ""}
														value={productionMode === "sum" ? statistic.sum.value / 1000 : statistic.count.value}
													/>
												</td>
												<td>
													<FormattedNumberText
														prefix={productionMode === "sum" ? "€ " : ""}
														value={
															productionMode === "sum" ? statistic.sum.targetYtd / 1000 : statistic.count.targetYtd
														}
													/>
												</td>
												<td>
													<span
														style={{
															color:
																productionMode === "sum"
																	? statistic.sum.percentage >= 100
																		? "#009286"
																		: "#D04228"
																	: statistic.count.percentage >= 100
																	  ? "#009286"
																	  : "#D04228",
														}}>
														<FormattedNumberText
															percentage
															value={productionMode === "sum" ? statistic.sum.percentage : statistic.count.percentage}
														/>
													</span>
												</td>
												<td>
													<FormattedNumberText
														prefix={productionMode === "sum" ? "€ " : ""}
														value={productionMode === "sum" ? statistic.sum.target / 1000 : statistic.count.target}
													/>
												</td>
											</tr>
										) : null,
									)}
								</tbody>
							</table>
						</div>

						<div className="mt-5">
							<table className="table flex">
								<thead>
									<tr>
										<td className="font-bold bg-primary text-white" colSpan={5}>
											{uctrans("dashboard.history_passed")}
										</td>
									</tr>
									<tr className="font-bold text-center">
										{blockData.tableData.map((year, index) => (
											<td
												style={index === 0 ? { textAlign: "center" } : {}}
												className="no-border text-center"
												key={index}>
												{year.year}
											</td>
										))}
									</tr>
								</thead>
								<tbody>
									<tr className="text-center">
										{blockData.tableData.map((year, index) => (
											<td className="no-border" style={index === 0 ? { textAlign: "center" } : {}} key={index}>
												<FormattedNumberText
													prefix={productionMode === "sum" ? "€ " : ""}
													value={productionMode === "sum" ? year.sum / 1000 : year.count}
												/>
											</td>
										))}
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="flex w-1/2">
						<LoanResultsLineChart
							year={blockData.year}
							productionData={blockData.diagramData}
							targetStatistics={blockData.targetStatistics}
							productionMode={productionMode}
							divideTooltipValueBy={1000}
						/>
					</div>
				</div>
			)}
		/>
	);
}
