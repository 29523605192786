import AgendaItemClosingRemarks from "@crm/react/components/agenda/AgendaItemClosingRemarks";
import PropTypes from "prop-types";
import { useState, useEffect, lazy, Suspense, useCallback, useMemo } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import axios from "axios";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import IconButton from "../../../../../js/react/components/general/IconButton";
import Spinner from "../../../../../js/react/components/general/Spinner";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import useRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import AgendaItemCompanyFields from "../agenda/AgendaItemCompanyFields";
import AgendaItemDateFields from "../agenda/AgendaItemDateFields";
import AgendaItemFormFields from "../agenda/AgendaItemFormFields";
import DuplicateKVK from "../agenda/DuplicateKVK";
import Navigation from "../agenda/Navigation";
import CompanyKvkData from "../company/CompanyKvkData";
import CompanyDossier from "../company/dossier/CompanyDossier";
import DossierSidebarSharedData from "../company/dossier/parts/DossierSidebarSharedData";
import CollapsibleContent from "../general/CollapsibleContent";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { can, route } from "../../../../../js/helpers";
import SideBarChangelog from "../general/SideBarChangelog";
import WarningGroups from "../general/warnings/WarningGroups";
import AFMPermits from "../intermediaries/statutory/AFMPermits";
import KifidChecks from "../intermediaries/statutory/KifidChecks";
import GenerateCooperationAgreement from "./cooperationAgreements/GenerateCooperationAgreement";

const TextEditor = lazy(() => import(/* webpackChunkName: "tinymce" */ "../forms/textEditor/TextEditor"));

export default function DossierForm(props) {
	const readOnly = window.data.read_only;

	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	const [currentStatus, setCurrentStatus] = useState(
		staticData.takeon_statuses.find(e => Number(e.id) === Number(formData.takeon_status_id)),
	);
	const [stayOnSamePage, setStayOnSamePage] = useState(false);
	const [warningTitle, setWarningTitle] = useState(null);
	const [activationWarnings, setActivationWarnings] = useState(null);
	const [activationWarningsBlocking, setActivationWarningsBlocking] = useState(null);
	const [warningsApproved, setWarningsApproved] = useState(false);
	const [isProspectRejected] = useState(
		Number(formData.company_status) === Number(staticData.company_statuses.PROSPECT_REJECTED.key),
	);
	const [isProspect] = useState(Number(formData.company_status) === Number(staticData.company_statuses.PROSPECT.key));
	const [isActive] = useState(Number(formData.company_status) === Number(staticData.company_statuses.ACTIVE.key));
	const [isNotActive] = useState(
		Number(formData.company_status) === Number(staticData.company_statuses.NOT_ACTIVE.key),
	);
	const [canChangeStatus] = useState(!isActive && !isProspectRejected && !isNotActive);

	const disabled = useMemo(() => {
		const permission = staticData.intermediary ? "intermediary" : "organisation";

		if (!can(permission, "update_takeon") && !can(permission, "update_all")) {
			return true;
		}

		return canChangeStatus ? readOnly : false;
	}, [canChangeStatus, readOnly, staticData.intermediary]);

	const canEditStatutoryDossier = useMemo(() => {
		if (disabled) {
			return false;
		}

		return (
			staticData.company_data.statutory_company &&
			staticData.company_data.statutory_company.main_company_id === staticData.company_data.id
		);
	}, [disabled, staticData]);

	const radioHandler = useRadioHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const onSuccess = () => {
		if (stayOnSamePage) {
			setStayOnSamePage(false);
			window.location.reload();
		} else {
			if (window.data.redirect_url) {
				Nav.go(window.data.redirect_url);
			} else if (window.data.agendaNamespace) {
				Nav.go(route("crm.agenda-items.index"));
			} else {
				window.location.reload();
			}
		}
	};

	const submitURL = () => {
		if (staticData.intermediary) {
			return route(`crm.agenda.intermediaries.dossier.update`, {
				intermediary: formData.id,
			});
		} else if (staticData.organisation) {
			return route(`crm.agenda.organisations.dossier.update`, {
				organisation: formData.id,
			});
		}
	};

	const handleFormValuesChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const getActivationWarnings = useCallback(async () => {
		try {
			const response = await axios.get(route(`crm.companies.activation-warnings`, { company: formData.id }));

			if (response.data.hasOwnProperty("title")) {
				setWarningTitle(response.data.title);
			}

			if (response.data.hasOwnProperty("blocking")) {
				setActivationWarningsBlocking(response.data.blocking);
			}

			setActivationWarnings(response.data.groups);
		} catch (error) {
			console.error(error);
		}
	}, [formData.id]);

	useEffect(() => {
		getActivationWarnings();
	}, [getActivationWarnings]);

	return (
		<>
			<Navigation
				selectedTab={window.data.nav_data.activeTabItem}
				tabs={window.data.nav_data.tabs}
				breadCrumbs={window.data.nav_data.breadcrumbs}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.company_name
						? uctrans("general.:item1 from :item2", { item2: staticData.company_name }, { item1: "companies.dossier" })
						: uctrans("companies.dossier")}
				</h1>

				{window.data.agendaNamespace ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={route("crm.intermediaries.index")}>
						{uctrans("general.back_to_overview_:page", {}, { page: "intermediaries.plural" })}
					</a>
				)}
			</div>

			<AjaxForm
				method="PUT"
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "intermediaries.dossier" })}
				successText={uctrans("general.saved_:item", {}, { item: "intermediaries.dossier" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				showConfirm={{
					when:
						Number(formData.company_status) === Number(staticData.company_statuses.ACTIVE.key) &&
						Number(staticData.current_status) !== Number(formData.company_status),
					cancelText: uctrans("general.cancel"),
					confirmText: uctrans("general.ok"),
					title: warningTitle,
					description: (
						<WarningGroups
							groups={activationWarnings}
							onApprove={() => {
								// we have blocking errors so we cannot approve the warnings
								if (!activationWarningsBlocking) {
									setWarningsApproved(true);
								}
							}}
						/>
					),
					blocking: !warningsApproved || activationWarningsBlocking,
				}}
				useFlashMessage={window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						{formData.takeon_agenda_item && (isProspect || isProspectRejected) ? (
							<CollapsibleContent initialCollapseState={false} title={uctrans("agendaitems.singular")}>
								<AgendaItemFormFields
									onChange={value => handleFormValuesChange("takeon_agenda_item", value)}
									agendaItemStaticData={staticData}
									agendaItemFormData={formData.takeon_agenda_item}
									editable={!disabled}
									showAgendaDate
								/>
							</CollapsibleContent>
						) : null}

						{!!staticData.company_data.statutory_company_id && (
							<div className="form-full">
								<CollapsibleContent
									initialCollapseState={false}
									title={uctrans("dossiers.fields.statutory_dossier_content")}>
									<CompanyDossier
										statutory
										readOnly={!canEditStatutoryDossier}
										onDossierChange={value => handleFormValuesChange("statutory_dossier_completions", value)}
										onDirectorChange={value => handleFormValuesChange("dossier_directors", value)}
										formData={formData}
										staticData={staticData}
									/>
								</CollapsibleContent>
							</div>
						)}

						<div className="form-full">
							<CollapsibleContent initialCollapseState={false} title={uctrans("dossiers.fields.dossier_content")}>
								<CompanyDossier
									readOnly={disabled}
									onDossierChange={value => handleFormValuesChange("dossier_completions", value)}
									formData={formData}
									staticData={staticData}
								/>
							</CollapsibleContent>
						</div>
						<div>&nbsp;</div>
						<CollapsibleContent initialCollapseState={false} title={uctrans("takeon_requests.status.singular")}>
							<>
								<div className="form-full">
									<label htmlFor="name">{uctrans("cooperation_check.annually.notes")}</label>
									<Suspense
										fallback={
											<div style={{ width: 32, height: 32 }}>
												<Spinner />
											</div>
										}>
										<TextEditor
											disabled={disabled || currentStatus?.is_final}
											value={formData.takeon_description}
											onChange={content => genericHandler(content, "takeon_description")}
											hideToolbar
										/>
									</Suspense>
								</div>

								{currentStatus?.is_final && (
									<AgendaItemClosingRemarks
										ClosingRemarks={formData.takeon_closing_remarks}
										SetClosingRemarks={event => genericHandler(event.target.value, "takeon_closing_remarks")}
										StatusIsFinal={currentStatus?.is_final}
										LabelAddClosingRemarks={uctrans("dossiers.add_closing_remarks")}
										TextAreaLabel="dossiers.fields.closing_remarks"
										TextAreaPlaceholder={uctrans("dossiers.fields.description_placeholder")}
										ReadOnly={readOnly}
									/>
								)}

								{canChangeStatus && !window.data.read_only ? (
									<div>
										<div className="form-1-3">
											<label htmlFor="takeon_status_id">
												<Translate content="takeon_requests.status.singular" />
											</label>
											<RadioGroup
												name="takeon_status_id"
												disabled={disabled}
												value={formData.takeon_status_id}
												onChange={value => {
													radioHandler(value, "takeon_status_id");
													const newStatus = staticData.takeon_statuses.find(e => Number(e.id) === Number(value));
													setCurrentStatus(newStatus);
													if (!newStatus.is_final) {
														formData.company_status = 1;
													}
												}}>
												{staticData.takeon_statuses.map(status => (
													<Radio label={status.name} value={status.id} key={status.id} />
												))}
											</RadioGroup>
										</div>
										{currentStatus && currentStatus.is_final && (
											<div className="form-1-3">
												<label htmlFor="company_status">
													<Translate content="takeon_requests.status.fields.result" />
												</label>
												<RadioGroup
													disabled={disabled}
													name="company_status"
													value={formData.company_status}
													onChange={value => radioHandler(value, "company_status")}>
													<Radio
														label={
															staticData.intermediary
																? uctrans("intermediaries.active_intermediary")
																: uctrans("organisations.active_organisation")
														}
														value={staticData.company_statuses.ACTIVE.key}
													/>
													<Radio
														label="Vervallen aanstellingsverzoek"
														value={staticData.company_statuses.PROSPECT_REJECTED.key}
													/>
												</RadioGroup>
											</div>
										)}
									</div>
								) : (
									<div>
										<div className="form-1-3">
											<label htmlFor="takeon_status_id">
												<Translate content="takeon_requests.status.singular" />
											</label>
											<p>
												{staticData.takeon_statuses !== null &&
												typeof staticData.takeon_statuses.find(
													e => Number(e.id) === Number(formData.takeon_status_id),
												) !== "undefined"
													? staticData.takeon_statuses.find(e => Number(e.id) === Number(formData.takeon_status_id))
															.name
													: "-"}
											</p>
										</div>

										<div className="form-1-3">
											<label htmlFor="company_status">
												<Translate content="takeon_requests.status.fields.result" />
											</label>
											<p>
												{staticData.company_statuses !== null &&
												typeof find(
													staticData.company_statuses,
													e => Number(e.key) === Number(formData.company_status),
												) !== "undefined"
													? find(staticData.company_statuses, e => Number(e.key) === Number(formData.company_status))
															.label
													: "-"}
											</p>
										</div>
									</div>
								)}
							</>
						</CollapsibleContent>
						<div className="form-full">
							<div className="row">
								<SubmitBar
									item="intermediaries.dossier"
									onSubmit={setStayOnSamePage}
									saveAndStayOnPage
									addAnotherAvailable={false}
									submitDisabled={disabled}
								/>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<div className="mt-10">
							{can("intermediary", "update_takeon") && (
								<GenerateCooperationAgreement companyId={staticData.company_data.id} />
							)}

							<CompanyKvkData
								companyId={staticData.company_data.id}
								mainCompany={staticData.company_data.is_main_company}
							/>
							<br />

							{staticData.company_data && staticData.company_data.statutory_company && (
								<>
									<span className="flex w-full">
										<b>{uctrans("intermediaries.fields.afm_number")}</b>
										{": "}
										{staticData.company_data.statutory_company.afm_number}
									</span>
									<span className="flex w-full">
										<b>{uctrans("intermediaries.fields.kifid_number")}</b>
										{": "}
										{staticData.company_data.statutory_company.kifid_number}
									</span>
								</>
							)}
						</div>

						{staticData.company_data && staticData.company_data.parent_id && (
							<CollapsibleContent initialCollapseState={false} title={uctrans("intermediaries.connected_organisation")}>
								<DossierSidebarSharedData formData={formData} staticData={staticData} />
							</CollapsibleContent>
						)}

						<AgendaItemDateFields agendaItem={formData.takeon_agenda_item} />

						{formData.takeon_agenda_item !== null && formData.takeon_agenda_item.company_id !== 0 ? (
							<AgendaItemCompanyFields companyId={formData.takeon_agenda_item.company_id} />
						) : (
							<AgendaItemCompanyFields companyId={formData.id} />
						)}

						{/* Only show AFM and Kifid if company is prospect */}
						{isProspect && (
							<>
								<AFMPermits
									afmNumber={staticData.afm_number}
									afmPermits={staticData.afm_existing_permits}
									missingPermits={staticData.afm_missing_permits}
									requiredPermits={staticData.afm_required_permits}
									afmShowChangeLog={false}
									afmChangeLogs={staticData.afm_changelogs}
									afmChangeLogTypes={staticData.afm_changelog_types}
									isOpen={staticData.afm_missing_permits.length}
								/>
								<KifidChecks
									kifidNumber={staticData.company_data.statutory_company.kifid_number}
									kifidType={staticData.company_data.statutory_company.kifid_type}
									kifidTypes={staticData.kifid_types}
								/>
							</>
						)}

						{staticData.changelog_changes_statutory && staticData.changelog_changes_statutory.afm_number && (
							<CollapsibleContent
								title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.afm_number" })}>
								<SideBarChangelog
									changelogData={staticData.changelog_changes_statutory.afm_number}
									changelogChangeTypes={staticData.changelog_change_types}
								/>
							</CollapsibleContent>
						)}

						{staticData.changelog_changes_statutory && staticData.changelog_changes_statutory.kifid_number && (
							<CollapsibleContent
								title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.kifid_number" })}>
								<SideBarChangelog
									changelogData={staticData.changelog_changes_statutory.kifid_number}
									changelogChangeTypes={staticData.changelog_change_types}
								/>
							</CollapsibleContent>
						)}

						{staticData.kvk_duplicate_check.has_duplicates && (
							<DuplicateKVK kvk_duplicate_check={staticData.kvk_duplicate_check} enums={staticData.enums} />
						)}

						<CollapsibleContent title={uctrans("intermediaries.fields.actions")} initialCollapseState={false}>
							{can("agenda.dossier_check", "create") ? (
								<div className="form-full">
									<IconButton
										href={
											staticData.intermediary
												? route("crm.intermediaries.dossier-checks.create", [formData.id])
												: route("crm.organisations.dossier-checks.create", [formData.id])
										}
										content={uctrans("general.add_:item", {}, { item: "dossiers.fields.manual_dossier_check" })}
										primary
									/>
								</div>
							) : null}
						</CollapsibleContent>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
DossierForm.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
};
