import EvaIcon from "@/react/components/general/EvaIcon";

export default function NoticeBar() {
	const notice = window.data?.notice_bar_message;

	return (
		notice && (
			<div className="w-full py-3 px-12 gap-4 flex items-center bg-secondary">
				<div>
					<EvaIcon className="flex items-center" type="info-outline" width={20} height={20} />
				</div>
				<div className="flex-1">
					<p>{notice}</p>
				</div>
			</div>
		)
	);
}
