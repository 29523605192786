import PropTypes from "prop-types";
import GeneralDossierFileInput from "./GeneralDossierFileInput";

export default function DossierFileInput(props) {
	return (
		<GeneralDossierFileInput
			storeRoute={props.storeRoute}
			public={props.public}
			existingFileDownloadRoute={props.existingFileDownloadRoute}
			newFileDownloadRoute={props.newFileDownloadRoute}
			maxFiles={props.maxFiles}
			acceptedExtensions={props.acceptedExtensions}
			initialFiles={props.initialFiles}
			initialArchivedFiles={props.initialArchivedFiles}
			onChange={props.onChange}
			onBeforeUpload={props.onBeforeUpload}
			clearAfterUpload={props.clearAfterUpload}
			useLoaders={props.useLoaders}
			disabled={props.disabled}
			showArchivedFiles={props.showArchivedFiles}
		/>
	);
}

DossierFileInput.propTypes = {
	storeRoute: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	existingFileDownloadRoute: PropTypes.string,
	maxFiles: PropTypes.number,
	acceptedExtensions: PropTypes.array,
	initialFiles: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
		PropTypes.objectOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
	]),
	initialArchivedFiles: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
		PropTypes.objectOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
	]),
	public: PropTypes.bool,
	onChange: PropTypes.func,
	onBeforeUpload: PropTypes.func,
	clearAfterUpload: PropTypes.bool,
	useLoaders: PropTypes.bool,
	disabled: PropTypes.bool,
	showArchivedFiles: PropTypes.bool,
};

DossierFileInput.defaultProps = {
	storeRoute: "crm.files.store",
	newFileDownloadRoute: "crm.files.download",
	maxFiles: 100,
	initialFiles: [],
	initialArchivedFiles: [],
	public: false,
	clearAfterUpload: false,
	useLoaders: true,
	disabled: false,
	showArchivedFiles: false,
};
